import React from 'react';

function OurWork() {
  return (
    <div>
      <h1>Our Work</h1>
      <p>Here are some of the projects we have worked on.</p>
    </div>
  );
}

export default OurWork;
