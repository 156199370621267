// src/pages/Home.js

import React from 'react';

function Home() {
  return (
    <div>
      {/* Hero Section */}
      <section style={{ background: '#333', color: '#fff', padding: '50px 0', textAlign: 'center' }}>
        <h1>Welcome to Our Digital Agency</h1>
        <p>We create beautiful digital experiences.</p>
      </section>

      {/* About Us Section */}
      <section style={{ padding: '50px 20px', textAlign: 'center' }}>
        <h2>About Us</h2>
        <p>We are a team of creative professionals dedicated to making your brand shine.</p>
      </section>

      {/* Latest Work Section */}
      <section style={{ padding: '50px 20px', textAlign: 'center', background: '#f4f4f4' }}>
        <h2>Our Latest Work</h2>
        <div>
          <p>Project 1: Stunning Website Design</p>
          <p>Project 2: Creative Branding Solutions</p>
          <p>Project 3: Innovative Marketing Campaign</p>
        </div>
      </section>

      {/* Footer */}
      <footer style={{ background: '#333', color: '#fff', padding: '20px 0', textAlign: 'center' }}>
        <p>&copy; 2024 Digital Agency. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Home;
